import { get, post, put, Delete } from "../axios";

// 导航列表-列表
export const navigateManageList = (params) =>
  get("/web/navigate/manage/list", params);

// 导航列表-新增
export const navigateManageAdd = (params) =>
  post("/web/navigate/manage/add", params);

// 导航列表-编辑
export const navigateManageUpdate = (params) =>
  put("/web/navigate/manage/update", params);

// 导航列表-删除
export const navigateManageDelete = (id) =>
  Delete("/web/navigate/manage/delete/" + id, "");
